// Libs.
import React from 'react';
// Deps.
import { graphql } from 'gatsby';
import Layout from '../components/layout/layout';
import SEO from '../components/layout/seo';
import StaticContentPage from '../components/common/static-content/static-content';
import { formatFieldsValue } from '../libs/formatFromGraph';
import { useTermsAndConditionsQuery } from '../hooks/useTermsAndConditionsQuery';

const RegistrationThankyou = () => {
  const {termsAndConditions:{title, body} = {}} = useTermsAndConditionsQuery();
  return (
    <Layout>
      <SEO title="Terms And Conditions" />
      <StaticContentPage  title={title} 
                          body={body} 
                          numItemsPerLine="1" 
                          viewAllLink="" />
    </Layout>
  );
};

export default RegistrationThankyou;
